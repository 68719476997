import { Filmstrip } from '../abstract/filmstrip.class';
import { FilmstripType } from '../abstract/filmstrip.type';
import { Module } from '../abstract/module.class';
import { ModuleType } from '../abstract/module.type';
import { Background } from '../object';

export class BlankFilmstrip extends Filmstrip {
  public override name = '空白頁';

  public override type = FilmstripType.BLANK;

  public override withMobileInteractive = true;

  public override projection = {
    background: new Background({
      type: 'IMAGE',
      src: 'assets/count-mario-bg.jpg',
    }),
  };

  public override mobile = {
    background: new Background(),
  };

  public override data = {};
}

export class BlankModule extends Module {
  public static override moduleName = '空白頁';

  public override name = '空白頁';

  public override type = ModuleType.BLANK;

  public override readonly filmstrips = [new BlankFilmstrip()];

  public override showInCreateDialogOther = true;

  public override data = {};

  public constructor(module: Partial<BlankModule> = {}) {
    super();
    this.fromJSON(module);
  }
}
