export enum ModuleType {
  SIGN_IN_WALL = 'SIGN_IN_WALL',
  COUNT_MARIO = 'COUNT_MARIO',
  FOOTER = 'FOOTER',
  HEADER = 'HEADER',
  SURVEY = 'SURVEY',
  SPINNER = 'SPINNER',
  DRAW_CARD = 'DRAW_CARD',
  BLANK = 'BLANK',
}
