import { Filmstrip } from '../../abstract/filmstrip.class';
import { FilmstripType } from '../../abstract/filmstrip.type';
import { Background } from '../../object';
import { Font } from '../../object/font/font.class';
import { Text } from '../../object/text/text.class';
import { $ } from '../../signal';
import { CountMarioModule } from '../count-mario.class';

export class CountMarioSection2Answer extends Filmstrip<CountMarioModule> {
  public name = '公佈答案';

  public override readonly type = FilmstripType.COUNT_MARIO_SECTION_2_ANSWER;

  public override readonly withMobileInteractive = true;

  public override hit = $.computed(() => {
    const options = this.module?.data?.options();
    if (!options) return null;

    const answers = options.filter((option) => option.isAnswer());
    if (answers.length > 1) {
      return '當有多個答案時，玩家只要選擇其中一個答案即可進入下一關';
    }

    return null;
  });

  public projection = {
    background: new Background({
      type: 'IMAGE',
      src: 'assets/count-mario/answer.jpg',
    }),
    publishTheAnswer: new Font({ align: 'center' }),
    answer: new Font({ align: 'center' }),
    numberOfWinner: new Font(),
  };

  public mobile = {
    background: new Background({ hex: '#D0D9E4' }),
    text: new Text(),
    alert: new Font({ align: 'center', bolder: true }),
  };

  public data = {};
}
