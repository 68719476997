import { Filmstrip } from '../abstract/filmstrip.class';
import { FilmstripType } from '../abstract/filmstrip.type';
import { Module } from '../abstract/module.class';
import { ModuleType } from '../abstract/module.type';
import { Background } from '../object';
import { Font } from '../object/font/font.class';
import { MultipleLineText } from '../object/multiple-line-text/multiple-line-text.class';
import { Text } from '../object/text/text.class';

export class SignInWallFilmstrip extends Filmstrip {
  public static workspaceHit =
    '玩家的頭貼會有漂浮動畫，請點擊右下角 <span class="mi">fullscreen</span> 預覽效果';

  public name = '簽到';

  public override readonly type = FilmstripType.SIGN_IN_WALL;

  public override readonly withMobileInteractive = true;

  public projection = {
    background: new Background({
      type: 'IMAGE',
      src: 'assets/count-mario/sign-in-wall.jpg',
    }),
    title: new Text({ content: '活動標題' }),
    numberOfAttendees: new Font(),
  };

  public mobile = {
    background: new Background({
      type: 'COLOR',
      hex: '#D2D9E3',
    }),
    title: new MultipleLineText({
      content: '您成功進入活動了喔！\n可以在大螢幕上找看看你的頭貼！',
    }),
  };

  public data = {};
}

export class SignInWallModule extends Module {
  public static override moduleName = '簽到牆';

  public override name = '簽到牆';

  public override readonly type = ModuleType.SIGN_IN_WALL;

  public override readonly filmstrips = [new SignInWallFilmstrip()];

  public override showInCreateDialog = false;

  public override data = {};

  public override isFixed = true;

  public constructor(module: Partial<SignInWallModule> = {}) {
    super();
    this.fromJSON(module);
  }
}
